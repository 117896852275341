<template>
<div class="header">
    <h1 class="header-font">
        <span class="pink-text">VESSELLINK</span> 
        <br>
        <span>COMING <br> SOON</span> 
</h1>
</div>
</template>

<script>
export default {

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');

.header{
  padding: 2rem 0;
}
@media screen and (max-width: 700px){
  .header{
  padding: 5px 0;
}
}

.pink-text {
color: hsl(227, 57%, 49%);
font-weight: 300;
}

.header-font {
  font-family: 'Josefin Sans';
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 5px;
}
@media screen and (max-width: 400px){
  .header-font {
    font-size: 1.5rem;
  }
}
</style>